const { __esModule } = require("bootstrap");

$(document).ready(function() {
  // layout
  // main padding-top
  $(function() {
    var paddingTopMain = function() {
      var heightHeader = $(".melon-container-fixed .melon-header").height();
      var addPaddingTarget = $(".melon-container-fixed .melon-main");
      $(addPaddingTarget).css("padding-top", heightHeader + "px");
    }
    paddingTopMain();
    $(window).resize(function(){
      paddingTopMain();
    });
  })

  // scroll
  $(window).scroll(function(){ 
    var topCurrent = $(document).scrollTop();
    var targetContainer = $(".melon-container, .melon-header");
    if(topCurrent > 0){
      $(targetContainer).addClass("scroll");
    } else {
      $(targetContainer).removeClass("scroll");
    }
  });

  // bottomsheet scroll
  function modalScroll () {
    var boxSelect = $(".box-bottomsheet");
    $(boxSelect).each(function() {
      var listSelect = $(this).find(".list-bottomsheet");
      var heightListSelect = listSelect.innerHeight();
      var heightBoxSelect = $(this).innerHeight();
      var scrollPadding = heightListSelect - heightBoxSelect;
      var scrollTopValue = $('.inner-box').scrollTop();
      var scrollGap = Math.floor(scrollTopValue - scrollPadding);
      
      if(heightListSelect >= heightBoxSelect) {
        $(this).addClass("scroll");
      } else {
        $(this).removeClass("scroll");
      }
      
      if(scrollGap >= -1) {
        $(this).closest(boxSelect).removeClass("scroll");
      } else {
        $(this).closest(boxSelect).addClass("scroll");
      }
      
      $(this).find(".inner-box").scroll(function(){
        var thisHeightListSelect = $(this).find(".list-bottomsheet").height();
        var thisHeightBoxSelect = $(this).height();
      
        if($(this).scrollTop() >= thisHeightListSelect - thisHeightBoxSelect){
          $(this).closest(boxSelect).removeClass("scroll");
        } else {
          $(this).closest(boxSelect).addClass("scroll");
        }
      });
    })
  }

  // bottomsheet
  $(function() {
    modalScroll();
  });

  // modal
  function modalClose (){
    var btnCancelModal = $(".melon-modal .layer-foot .btn-cancel");
    $(btnCancelModal).on("click", function() {
      $(this).closest(".melon-modal").hide();
      $('html').removeClass("dimmed");
    });
    $(".melon-modal").click(function(e){
      if(!$('.modal-layer').has(e.target).length) { 
        $(".melon-modal").hide();
        $('html').removeClass("dimmed");
      }
    });
    var btnCloseModal = $(".melon-modal .btn-close");
    $(btnCloseModal).on("click", function() {
      $(this).closest(".melon-modal").hide();
      $('html').removeClass("dimmed");
    });
  }
  modalClose();
  $(function() {
    var btnModal = $("[data-button-type='modal']");
    $(btnModal).each(function() {
      $(this).on("click", function() {
        var targetModal = $(this).attr("data-target");
        $('html').addClass("dimmed");
        $(targetModal).show();
        modalScroll();
      })
    });
  });

  // floating
  $(function() {
    var btnCloseFloating = $(".melon-floating .btn-close");
    $(btnCloseFloating).on("click", function() {
      $(this).closest(".melon-floating").hide();
    })
  });

  // text wrap - fold
  $(function() {
    var textWrap = $(".text-wrap")
    // n줄이상 더보기
    var txtOverflow = function() {
      $(textWrap).each(function (index, item) {
        var innerTextWrap = $(this).find(".txt-g");
        var heightCmtText = $(this).innerHeight();
        var heightCmTxt = innerTextWrap.innerHeight();
        if(heightCmTxt > heightCmtText) {
          $(this).addClass("text-overflow");
        } else {
          $(this).removeClass("text-overflow");
        }
      })
    }
    txtOverflow();
    $(window).resize(function(){
      txtOverflow();
    });
    // 더보기/접기
    var btnTxtFold = $(textWrap).find(".btn-text");
    $(btnTxtFold).on("click", function() {
      $(this).closest(textWrap).toggleClass("text-open");
    })
  });

  // play button - scroll fixed 
  $(function() {
    var btnPlayFixed = function() {
      var heightHeader = $(".melon-container .melon-header").innerHeight();
      var targetPlayAction = $(".play-action-fixed");
      $(targetPlayAction).each(function() {
        var innerPlayAction = $(this).find(".inner-play-action");
        var heightInnerPlayAction = $(innerPlayAction).innerHeight();
        var topPlayAction = $(this).offset().top;
        var topCurrent = $(document).scrollTop();
        if(topCurrent > topPlayAction - heightHeader - 4){
          $(targetPlayAction).addClass("fixed").css("height", heightInnerPlayAction + "px");;
          $(innerPlayAction).css("top", heightHeader + "px");
        } else {
          $(targetPlayAction).removeClass("fixed").css("height", "");
          $(innerPlayAction).css("top", "");
        }
      });
    };
    $(window).scroll(function(){ 
      btnPlayFixed();
    });
    $(window).resize(function(){
      btnPlayFixed();
    });
  });

  // wrap info list - scroll fixed 
  $(function() {
    var listInfoFixed = function() { 
      var heightHeader = $(".melon-container .melon-header").innerHeight();
      var targetWrapInfo = $(".wrap-info-fixed");
      $(targetWrapInfo).each(function() {
        var innerInfo = $(this).find(".inner-info-list");
        var heightInnerInfo = $(innerInfo).innerHeight();
        var topWrapInfo= $(this).offset().top;
        var topCurrent = $(document).scrollTop();
        var heightPlayAction = $(".play-action-fixed .inner-play-action").innerHeight();
        if(topCurrent > topWrapInfo - heightHeader - heightPlayAction - 7){
          $(targetWrapInfo).addClass("fixed").css("height", heightInnerInfo + "px");;
          $(innerInfo).css("top", heightHeader + heightPlayAction + "px");
        } else {
          $(targetWrapInfo).removeClass("fixed").css("height", "");
          $(innerInfo).css("top", "");
        }
      });
    };
    $(window).scroll(function(){ 
      listInfoFixed();
    });
    $(window).resize(function(){
      listInfoFixed();
    });
  });

  // 더보기 리스트
  $(function() {
    // 아티스트 더보기 없을 경우 여부 확인
    var DataList = function(part, list, max) {
        $(this).part = part;
        $(this).list = list;
        $(this).max = max;
        var dataBox = $(part);
        dataBox.each(function (index, item) {
          var dataLength = $(this).find(list).length;
          var areaMore = $(this).find(".area-more");
          if(dataLength < max) {
            areaMore.css("display", "none");
          }
        });
    }
    var dataListOpen = new DataList('.data-list', 'li', '3');
    var dataListOpen2 = new DataList('.data-list2', 'li', '5');

    // 텍스트 라인 수에 따르는 경우 (아티스트 소개)
    var DataView = function(part, data, height) {
      $(this).part = part;
      $(this).data = data;
      var dataBox = $(part);
      dataBox.each(function (index, item) {
        var dataHeight = $(this).find(data).height();
        var areaMore = $(this).find(".area-more");
        var contHeight = Number(height);
        if(dataHeight < contHeight) {
          areaMore.css("display", "none");
        } else {
          areaMore.css("display", "");
        }
      });
    }

    // 앨범 > 가사 더보기
    var DataView2 = function(part, data, height) {
      $(this).part = part;
      $(this).data = data;
      var dataBox = $(part);
      dataBox.each(function (index, item) {
        var dataHeight = $(this).find(data).height();
        var areaMore = $(this).find(".area-more");
        var contHeight = $(this).find(height).height();
        if(dataHeight >= contHeight) {
          areaMore.css("display", "none");
        } else {
          areaMore.css("display", "");
          $(".txt-lyrics").addClass('txt-clamp');
        }
      });
    }

    var dataViewOpen = new DataView('.data-view', '.unit-cont', '208');
    var dataViewOpen2 = new DataView2('.data-view2', '.txt-out', '.txt-lyrics');

    $(window).resize(function() {
      //창크기 변화 감지
      var dataViewOpen = new DataView('.data-view', '.unit-cont', '208');
    });
    
    
    // 더보기 컨텐츠 버튼 화살표 (btn-more-data가 있을 경우만)
    var viewMore = function(data) {
      $(data).each(function (index, item) {
        var contentFold = data.hasClass('open-more');
        var btnMore = $(this).find(".btn-more-data");
        if(contentFold) {
          data.removeClass('open-more');
          btnMore.removeClass('on');
        } else {
          data.addClass('open-more');
          btnMore.addClass('on');
        }
      });
    }

    // 더보기 클릭 버튼명 수정 (공통)
    var clickFold = function(){
      var buttonFold = $(".data-view").find(".btn-more-data"); // 소개 더보기 - 5줄 이상 더보기 / 접기
      var buttonFoldLyric = $(".data-view2").find(".btn-more"); // 가사 더보기 - 15줄 이상 더보기 / 접기
      var buttonFold2 = $(".data-list").find(".btn-more-data") ; // 아티스트 더보기  - 3리스트 이상 더보기만
      var buttonFold3 = $(".data-list2").find(".btn-more-data"); // 매거진 더보기  - 5리스트 이상 더보기만

      var ViewFold = function(item){
        $(item).on("click", function() {
          var data = $(this).parent().parent();
          var textFold = $(this).find(".txt-g");
          textFold.text(textFold.text() == '더보기' ? '접기' : '더보기');
          if(data) {
            viewMore(data);
          }
          if(item === buttonFoldLyric) {
            $('html, body').animate({scrollTop : 420});
          }
        });
      }
      var viewFold = new ViewFold(buttonFold);
      var viewFoldLyric = new ViewFold(buttonFoldLyric);
      var viewFold2 = new ViewFold(buttonFold2);
      var viewFold3 = new ViewFold(buttonFold3);
    }
    clickFold();
  });

  //좋아요, 팬버튼 lottie animation
  // $(function () {

  //   let btnFanIcon = document.querySelectorAll(".btn-fan");
  //   let btnLikeIcon = document.querySelectorAll(".btn-like");
  //   let fanLottie = document.querySelector("#fanIcon");
  //   let likeLottie = document.querySelector("#likeIcon");

  //   let toastLike = document.querySelector(".popup-toast-like");
  //   let toastFan = document.querySelector(".popup-toast-fan");

  //   let handleClick = (btn, icon, ani, popup) => {      
  //     if (btn && icon && ani && popup) {

  //       let timeoutId;        
  //       function popupToast(time) {
  //         popup.style.display = "block";
  //         btn.disabled = true;
  //         clearTimeout(timeoutId);
  //         timeoutId = setTimeout(() => {
  //           popup.style.display = "none";
  //           btn.disabled = false;
  //         }, time);  
  //       }

  //       if (!btn.classList.contains("on")) {
  //         ani.play();
  //         btn.classList.add("on")
  //         icon.classList.add("on")
  //         setTimeout(() => {
  //           icon.classList.remove("on");
  //           ani.stop();
  //         }, 1000);
  //         popupToast(3000);
  //       } else {
  //         btn.classList.remove("on")
  //         icon.classList.remove("on")
  //         popupToast(1000);
  //       }
  //     }
      
  //   };
  //   btnFanIcon.forEach(btnfan => {
  //     btnfan.addEventListener("click", () => handleClick(btnfan, fanLottie, aniFan, toastFan));
  //   });
  //   btnLikeIcon.forEach(btnlike => {
  //     btnlike.addEventListener("click", () => handleClick(btnlike, likeLottie, aniLike, toastLike));
  //   });
  // })
});